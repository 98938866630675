import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { IoMenuSharp } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar';
import { Account } from 'appwrite';
import appwriteClient from '../appwrite.config';

function Home() {
    const navigate = useNavigate();
    const account = new Account(appwriteClient);

   
         account.listSessions().then((response)=>{
       console.log(response)
        }).catch((error)=>{
     console.log(error)
     navigate('/login', { replace: true })
        })
       
   
      
   
     



    
  
    const[menuVisible,setMenuVisible]=useState(false);
const [tyrePicked,setTyrePicked]=useState(200);
const [tyreDelivered,setTyreDelivered]=useState(100);
const [tyreTracked,setTyreTracked]=useState(400);
const [tripCompleted,setTripCompleted]=useState(30);
const [dayBookData,setDayBookData]=useState([]);

const dayBookArr=[
    {
type:"Fleet Profile",
fleetName:"Kanker Roadways Pvt. Ltd.",
tyreSerialNo:"",
vehicleRegNo:"",
user:"Pandey Ji",
date:"2024/03/15",
time:"22:15"
    },
    {
        type:"Vehicle Profile",
        fleetName:"Kanker Roadways Pvt. Ltd.",
        tyreSerialNo:"",
        vehicleRegNo:"CG04MN0964",
        user:"Pandey Ji",
        date:"2024/03/15",
        time:"22:15"
            },
            {
                type:"Tyre Fitment",
                fleetName:"Kanker Roadways Pvt. Ltd.",
                tyreSerialNo:"ABC12345U",
                vehicleRegNo:"CG04MN0964",
                user:"Pandey Ji",
                date:"2024/03/15",
                time:"22:15"
                    },
                    {
                        type:"Tyre Tracking",
                        fleetName:"Bituboy Overseas",
                        tyreSerialNo:"ABC12345U",
                        vehicleRegNo:"CG04MN0964",
                        user:"Pandey Ji",
                        date:"2024/03/15",
                        time:"22:15"
                            },
                            {
                                type:"Tyre Removal",
                                fleetName:"Kanker Roadways Pvt. Ltd.",
                                tyreSerialNo:"ABC12345U",
                                vehicleRegNo:"CG04MN0964",
                                user:"Pandey Ji",
                                date:"2024/03/15",
                                time:"22:15"
                                    },
                                    {
                                        type:"Trip Creation",
                                        fleetName:"Kanker Roadways - BituBoy - Urja",
                                        tyreSerialNo:"",
                                        vehicleRegNo:"CG04MM0000",
                                        user:"Pandey Ji",
                                        date:"2024/03/15",
                                        time:"22:15"
                                            },
                                            {
                                                type:"Work Order",
                                                fleetName:"Kanker Roadways Pvt. Ltd.",
                                                tyreSerialNo:"ABC12345U",
                                                vehicleRegNo:"",
                                                user:"Pandey Ji",
                                                date:"2024/03/15",
                                                time:"22:15"
                                                    },
                                                    {
                                                        type:"Invoice",
                                                        fleetName:"Kanker Roadways Pvt. Ltd.",
                                                        tyreSerialNo:"ABC12345U",
                                                        vehicleRegNo:"",
                                                        user:"Pandey Ji",
                                                        date:"2024/03/15",
                                                        time:"22:15"
                                                            },
                    
]
useEffect(()=>{
    setDayBookData(dayBookArr);
},[])



    return (
<>

<div className='flex flex-row'>
    
   {
    <div className={` bg-slate-100 w-80 ${menuVisible?'max-sm:flex-col':'max-sm:hidden'} max-sm:sticky p-5 h-screen max-sm:flex-row  flex-row  max-sm:p-1`}>
    <div className='max-sm:flex max-sm:p-1 max-sm:w-full'>
    <div className='hidden  max-sm:flex max-sm:my-auto max-sm:justify-center max-sm:mx-6'>{
        <button onClick={()=>{setMenuVisible(!menuVisible)}}>
        <IoMenuSharp  size={20} />
        </button>
        }</div>
        <div className=' text-2xl w-fit font-bold font-serif text-center mx-auto mb-6 max-sm:mb-0 max-sm:text-xl  hover:cursor-pointer'>
 
Maa Tyre Retreads
</div>
</div>

<div style={{height:"90%"}} className='flex-col py-5 mb-15 w-full  text-center overflow-y-scroll'>
    <h1 className='mb-3'>Tyre Pickup & Delivery</h1>
<div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 '  to='/workorder'>Work Order</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white  ml-8 mr-8'>
    <Link className=' py-3 ' to='/pickuprequest'>Pickup Request</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/invoice'>Invoice</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/trippanel'>Trip Panel</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className=' py-3 ' to='/namelist'>Name List</Link>
    </div>
    <h1 className='mb-3'>Tyre Tracking</h1>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/tyrefitment'>Fitment Details</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/tyretracking'>Tracking Details</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/tyreremoval'>Removal Details</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className=' py-3 ' to='/pickuprequest'> Projection</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className=' py-3 ' to='/pickuprequest'>Vehicle Profile</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className=' py-3 ' to='/pickuprequest'>Tyre Profile</Link>
    </div>
    <h1 className='mb-3'>User Panel</h1>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/useractivity'>User Activity</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/useractivity'>User Register</Link>
    </div>
    <div className='mb-4 bg-slate-300 py-3 rounded-md justify-center hover:bg-blue-500 hover:text-white ml-8 mr-8'>
    <Link className='  py-3 ' to='/tyretracking'>Location Tracking</Link>
    </div>
</div>

    </div>
    }
    {
    <div className={`w-4/5 max-sm:w-full  h-screen  flex-col ${menuVisible?'max-sm:hidden':'max-sm:flex'}`}>
<div className='w-full '><Navbar page={"Home"}/></div>
<div className='w-full  p-5   flex-wrap  rounded-sm'>
    <div className='flex-row hidden max-sm:flex'>
       
    <button className='absolute left-2 top-3' onClick={()=>{setMenuVisible(!menuVisible)}}>
        <IoMenuSharp  size={25} />
        </button>
        
       
    </div>
    
    <div className='flex-row mt-2 w-full flex flex-wrap justify-evenly'>
       
<div className='w-40 h-36 rounded-2xl m-3 p-5 max-sm:w-36 max-sm:h-36 bg-orange-300'>
        <h1 className='text-2xl text-center font-semibold'>Tyre Picked</h1>
        <h1 className='text-3xl text-center font-semibold'>{tyrePicked}</h1>
        
</div>
<div className='w-40 h-36 rounded-2xl m-3 p-5 max-sm:w-36 max-sm:h-36 bg-red-300'>
<h1 className='text-2xl text-center font-semibold'>Tyre Delivered</h1>
        <h1 className='text-3xl text-center font-semibold'>{tyreDelivered}</h1>
</div>
<div className='w-40 h-36 rounded-2xl m-3 p-5 max-sm:w-36 max-sm:h-36 bg-yellow-300'>
<h1 className='text-2xl text-center font-semibold'>Tyre Tracked</h1>
        <h1 className='text-3xl text-center font-semibold'>{tyreTracked}</h1>
</div>
<div className='w-40 h-36 rounded-2xl m-3 py-5 max-sm:p-4 max-sm:w-36 max-sm:h-36 bg-green-300'>
<h1 className='text-2xl text-center font-semibold'>Trip Completed</h1>
        <h1 className='text-3xl text-center font-semibold'>{tripCompleted}</h1>
</div>
    </div>

</div>
<div className='w-full  flex-col p-5 max-sm:overflow-scroll overflow-clip'>

<table className="table-auto w-full bg-blue-400 text-white">
        <thead className='static'>
          <tr>
            <th className="px-4 py-2">Type</th>
            <th className="px-4 py-2">Fleet Name</th>
            <th className="px-4 py-2">Tyre Serial No.</th>
            <th className="px-4 py-2">Vehicle Reg. No.</th>
            <th className="px-4 py-2">User</th>
            <th className="px-4 py-2">Date and Time</th>
          </tr>
        </thead>
        <tbody>
          {
            dayBookData.map((data)=>{
                return(
                    <tr className='bg-white text-black border-2'>
                    <td className="px-4 py-2">{data.type}</td>
                    <td className="px-4 py-2">{data.fleetName}</td>
                    <td className="px-4 py-2">{data.tyreSerialNo}</td>
                    <td className="px-4 py-2">{data.vehicleRegNo}</td>
                    <td className="px-4 py-2">{data.user}</td>
                    <td className="px-4 py-2">{data.date+" "+data.time}</td>
                  </tr>
                )
            })
          }
        </tbody>
      </table>
</div>
    </div>
    }
</div>
</>
  )
}

export default Home