import logo from './logo.svg';
import '@radix-ui/themes/styles.css';
import { Theme } from '@radix-ui/themes';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  BrowserRouter,
  useNavigate
} from "react-router-dom";
import Home from './screens/Home';
import WorkOrder from './screens/WorkOrder';
import WorkOrderPreview from './screens/WorkOrderPreview';
import NameList from './screens/NameList';
import Login from './screens/Login'
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import InvoiceRegister from './screens/InvoiceRegister';
import PickupRequest from './screens/PickupRequest';
import TripPanel from './screens/TripPanel';
import TyreFitment from './screens/TyreFitment';
import DataImporting from './screens/DataImporting';
import TyreTracking from './screens/TyreTracking';
import TyreRemoval from './screens/TyreRemoval';
import UserActivity from './screens/UserActivity';
//import '@coreui/coreui/dist/css/coreui.min.css'
//import 'bootstrap/dist/css/bootstrap.min.css'





function App() {
  
  return (
    
    <div className="App">
      
     <BrowserRouter>
     <Routes>
      <Route path={"/"} element={<Home/>}/>
      <Route path={"/workorder"} element={<WorkOrder/>}/>
      <Route path={"/workorderPreview/:id"} element={<WorkOrderPreview/>}/>
      <Route path={"/namelist"} element={<NameList/>}/>
      <Route path={"/login"} element={<Login/>}/>
      <Route path={"/invoice"} element={<InvoiceRegister/>}/>
      <Route path={'/pickuprequest'} element={<PickupRequest/>}/>
      <Route path={'/trippanel'} element={<TripPanel/>}/>
      <Route path={'/tyrefitment'} element={<TyreFitment/>}/>
      <Route path={'/dataimport'} element={<DataImporting/>}/>
      <Route path={'/tyretracking'} element={<TyreTracking/>}/>
      <Route path={'/tyreremoval'} element={<TyreRemoval/>}/>
      <Route path={'/useractivity'} element={<UserActivity/>}/>
     </Routes>
     </BrowserRouter>
     
    </div>
  );
}

export default App;
