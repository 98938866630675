import React, { useState,useEffect } from 'react'
import Navbar from '../components/Navbar';
import { TbFilterCog } from "react-icons/tb";
import appwriteClient from '../appwrite.config';
import { Databases, Query } from 'appwrite';
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'
import Multiselect from 'multiselect-react-dropdown';
import {Collapse} from 'react-collapse';
import AsyncSelect from 'react-select/async';
import { Link, useNavigate } from 'react-router-dom';
import XLSX from 'xlsx';
import * as FileSaver from 'file-saver'

const TyreTracking = () => {

    const [columns,setColumns]=useState([
        {
          title:"Action",
          key:"action"
        },
        {
            title:"Fleet Name",
              key:"fleetName",
              size:"12"
          },
        //   {
        //     title:"Avg Run",
        //       key:"avgRun",
        //       size:"5"
        //   },
        {
            title:"Vehicle Type",
              key:"vehicleType",
              size:"10"
          },
          {
              title:"Vehicle Reg. No.",
              key:"vehicleRegistrationNumber",
              size:"15"
          },
          {
              title:"Position",
              key:"wheelPosition",
              size:"5"
          },
          {
              title:"Tyre M&P",
              key:"tyreMake",
              size:"10"
          },
        //   {
        //     title:"Tyre Status",
        //       key:"tyreStatus",
        //       size:"14"
        //   },
          {
            title:"Tread M&P",
              key:"treadMake",
              size:"10"
          },
        //   {
        //       title:"Tyre Size",
        //       key:"tyreSize",
        //       size:"10"
        //   },
          {
              title:"Tyre Serial No.",
              key:"tyreSerialNumber",
              size:"10"
          },
          {
            title:"Track. ODO",
              key:"ODOReading",
              size:"8"
          },
          {
            title:"Track. TD",
              key:"rtd",
              size:"4"
          },
          {
            title:"Track. Date",
              key:"trackingDate",
              size:"5"
          },
          {
            title:"Track. Air Pres.",
              key:"airPressure",
              size:"5"
          },
          {
              title:"Fitment Date",
              key:"fitmentDate",
              size:"8"
          },
          {
              title:"Fitment ODO",
              key:"ftmentODO",
              size:"8"
          },
          {
              title:"Remarks",
              key:"remarks",
              size:"10"
          },
          {
            title:"Fitment TD",
              key:"fitmentTD",
              size:"3"
          },
         
        //   {
        //     title:"Fitment Status",
        //       key:"fitmentStatus",
        //       size:"8"
        //   },
          
          {
            title:"User",
              key:"createdBy",
              size:"8"
          },
          {
            title:"Time Stamp",
              key:"createdAt",
              size:"10"
          },
          
          ]);



          const [isFilterOptionVisible,setIsFilterOptionVisible]=useState(true);
          const [tyreTrackingData,setTyreTrackingData]=useState([]);
          const [loading,setLoading]=useState(false)
          var filteredData;
      const [lastId,setLastId]=useState("");
      const [itemsPerPage,setItemsPerPage]=useState(10);
      const [pageNo,setPageNo]=useState(1);
      const [currentPage, setCurrentPage] = useState(1);
      const [updateFitment,setUpdateFitment]=useState();
      const [updateDocId,setUpdateDocId]=useState();
      const [totalPages,setTotalPages]=useState();
      const [isUpdateFormVisible,setIsUpdateFormVisible]=useState(false);
      const [presentTrackingId,setPresentTrackingId]=useState();
      const navigate = useNavigate();

      const [fleetNameDropdown,setFleetNameDropdown]=useState([]);
      const [vehicleRegNoDropdown,setVehicleRegNoDropdown]=useState([]);
      const [vehicleTypeDropdown,setVehicleTypeDropdown]=useState([]);
      const [tyreSizeDropdown,setTyreSizeDropdown]=useState([]);
      const [tyreMakeDropdown,setTyreMakeDropdown]=useState([]);
      const [treadMakeDropdown,setTreadMakeDropdown]=useState([]);
      const [tyreStatusDropdown,setTyreStausDropdown]=useState([
       {
          value:"New Tyre",
          label:"New Tyre"
       },
       {
          value:"Retread Tyre",
          label:"Retread Tyre"
       },
       {
          value:"New Running Tyre",
          label:"New Running Tyre"
       },
       {
          value:"Retread Running Tyre",
          label:"Retread Running Tyre"
       },
      ]);
      const [dataType,setDataType]=useState("Tracking Data");
   
      const [selectedFleetname,setSelectedFleetname]=useState([]);
      const [selectedVehicleRegNo,setSelectedVehicleRegNo]=useState([]);
      const [selectedVehicleType,setSelectedVehicleType]=useState([]);
      const [selectedTyreStatus,setSelectedTyreStatus]=useState([]);
      const [fromDate,setFromDate]=useState();
      const [toDate,setToDate]=useState();
      const [selectedTyreSize,setSelectedTyreSize]=useState("");
      const [selectedTyreMake,setSelectedTyreMake]=useState("");
      const [selectedTreadMake,setSlectedTreadMake]=useState("");
      const databases = new Databases(appwriteClient);
     

      let indexSNo=1;
      const [totalRows,setTotalRows]=useState(0);
          const toggleUpdateFormVisibility=(id)=>{
              if(!isUpdateFormVisible){
               setUpdateDocId(id)
              }
             
             
              setIsUpdateFormVisible(!isUpdateFormVisible);
          }



          
    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            '65ea96670e2c9740a984',
            [
                Query.limit(500),
                
            ]
        ).then((response)=>{
            const fleetNameDropdownarr=[];
            response.documents.map((data)=>{
                fleetNameDropdownarr.push(
                {value:data.fleetname,label:data.fleetname}
              ) 
            })
            setFleetNameDropdown(fleetNameDropdownarr);
        }).catch((error)=>{
            toast.error("An error occurred while getting Fleetname dropdown, Please try again later !!");
            console.log(error);
        })
    },[])

    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'VehicleTypeDropdown',
            [
                Query.limit(100),
                
            ]
        ).then((response)=>{
            const vehicleTypeDropdownarr=[];
            response.documents.map((data)=>{
                vehicleTypeDropdownarr.push(
                {value:data.vechicleType,label:data.vechicleType}
              ) 
            })
            setVehicleTypeDropdown(vehicleTypeDropdownarr);
        }).catch((error)=>{
            toast.error("An error occurred while getting Vehicle type dropdown, Please try again later !!");
            console.log(error);
        })
    },[])


    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'TyreSizeDropDown',
            [
                Query.limit(500),
                
            ]
        ).then((response)=>{
            const tyreSizeDropdownarr=[];
            response.documents.map((data)=>{
                tyreSizeDropdownarr.push(
                {value:data.displayName,label:data.displayName}
              ) 
            })
            setTyreSizeDropdown(tyreSizeDropdownarr);
        }).catch((error)=>{
            toast.error("An error occurred while getting Tyre size dropdown, Please try again later !!");
            console.log(error);
        })
    },[])

    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'TyreMakeandPatternDropDown',
            [
                Query.limit(500),
                
            ]
        ).then((response)=>{
            const tyreMakeDropdownarr=[];
            response.documents.map((data)=>{
                tyreMakeDropdownarr.push(
                {value:data.displayName,label:data.displayName}
              ) 
            })
            setTyreMakeDropdown(tyreMakeDropdownarr);
        }).catch((error)=>{
            toast.error("An error occurred while getting Tyre Make dropdown, Please try again later !!");
            console.log(error);
        })
    },[])


    useEffect(()=>{
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'TreadMakeandPattern',
            [
                Query.limit(500),
                
            ]
        ).then((response)=>{
            const treadMakeDropdownarr=[];
            response.documents.map((data)=>{
                treadMakeDropdownarr.push(
                {value:data.displayName,label:data.displayName}
              ) 
            })
            setTreadMakeDropdown(treadMakeDropdownarr);
        }).catch((error)=>{
            toast.error("An error occurred while getting Tread Make dropdown, Please try again later !!");
            console.log(error);
        })
    },[])


    useEffect(()=>{
       if(selectedFleetname.length>0){
           
        databases.listDocuments(
            'AppwriteCloudFEApp',
            'VehicleProfile',
            [
                Query.equal("fleetname",selectedFleetname),
                Query.limit(1000)
                
            ]
        ).then((response)=>{
            const vehicleDropdownArr=[]
           
            response.documents.map((data)=>{
               
              vehicleDropdownArr.push(
                {value:data.vehicleRegistrationNumber,label:data.vehicleRegistrationNumber}
              ) 
            })
    
            setVehicleRegNoDropdown(vehicleDropdownArr)
        }).catch((error)=>{
            toast.error("An error occurred while getting Vehicle dropdown, Please try again later !!");
            console.log(error);
        })
       }
    },[selectedFleetname])




    const handleSubmit = async ()=>{
        setLoading(true);
        if(selectedFleetname.length===0){
           toast.error("Please select one or more Fleetname to proceed !!");
        }
        if(fromDate && !toDate){
            toast.error("Please select both From and To date !!");
        }
        if(!fromDate && toDate){
            toast.error("Please select both From and To date !!");
        }
       const queries= [
            Query.equal("fleetName",selectedFleetname),
            Query.limit(itemsPerPage),
            Query.orderDesc("$createdAt"),
            currentPage!==1?Query.cursorAfter(lastId):Query.offset(0)
        ]
       if(selectedVehicleRegNo.length>0){
        queries.push(Query.equal("vehicleRegistrationNumber",selectedVehicleRegNo))
       }
       if(selectedVehicleType.length>0){
        queries.push(Query.equal("vehicleType",selectedVehicleType))
       }
       if(selectedTyreStatus.length>0){
        queries.push(Query.equal("tyreStatus",selectedTyreStatus))
       }
       if(selectedTyreSize.length>0){
        queries.push(Query.equal("tyreSize",selectedTyreSize))
       }
       if(fromDate && toDate){
        queries.push(Query.greaterThan("trackingDate",fromDate))
        queries.push(Query.lessThan("trackingDate",toDate))
       }
      
       if(currentPage*itemsPerPage>filteredData?.length-1 ){
        await databases.listDocuments(
            'AppwriteCloudFEApp',
            'TrackingData',
            queries
        ).then((response)=>{
            setLastId(response.documents[response.documents.length - 1]?.$id);
            if(currentPage===1){setTotalRows(response.total)};
             (currentPage===1)?setTyreTrackingData(response.documents):
              setTyreTrackingData([...tyreTrackingData,...response.documents]);
              setIsFilterOptionVisible(false)
              setLoading(false)
        }).catch((error)=>{
            toast.error("Unable to get fitment data, Please try again later !!")
            console.log(error)
            setLoading(false)
        })}
        setLoading(false)
    }

useEffect(()=>{
    if(filteredData.length>0){
     
    handleSubmit()
        
    }
},[currentPage, itemsPerPage])

const fileType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
      
const fileExtension = '.xlsx';

const exportToExcel = async () => {

const ws = XLSX.utils.json_to_sheet (tyreTrackingData);

const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });

const data = new Blob ([excelBuffer], { type: fileType });

FileSaver.saveAs (data, "Tracking Data "+selectedFleetname + fileExtension);
}


const [filterText, setFilterText] = useState({});

const handleFilterChange  =  (key, value) => {
 
    setFilterText(()=>{return { ...filterText, [key]: value }});
   
   
   
 };

 const applyFilters = (row) => {

   return Object.entries(filterText).every(([key, values]) => {
     
     const cellValue = row[key].toLowerCase();

     return values.some(filterValue =>{ if(filterValue){return cellValue===(filterValue.toLowerCase())}return true} );
   }
);};

  filteredData = tyreTrackingData.filter(row => applyFilters(row));
 useEffect(()=>{
   if(totalRows){
   setTotalPages( Math.ceil(totalRows / itemsPerPage))
 }
 },[totalPages,filteredData,itemsPerPage,currentPage])
 
 const handleChangePage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    
    }
  };


  const renderTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
       
    if(filteredData.length>=1){
      
    return filteredData.slice(startIndex, endIndex).map((row, index) => {

      return (
        <>
        <tr key={row.$id} className='text-center border-t-2 bg-white text-black'>
          <td>{
            <div className='flex px-3'>
                {indexSNo++}
            {/* <Link to={`/workorderPreview/${row["workOrderNumber"]}`} state={{WorkOrder:row["workOrderNumber"]}} >
              <CiExport  className='mx-2 hover:cursor-pointer' color='green' size={25}/>
            </Link>
            <button onClick={()=>toggleUpdateFormVisibility(row["workOrderNumber"])}>
            <CiEdit className='mx-2 hover:cursor-pointer ' color='blue' size={25}/>
            </button>
            <button onClick={()=>deleteWorkOrder(row["workOrderNumber"])}>
            <MdDeleteOutline className='mx-2 hover:cursor-pointer' color='orange' size={25} />
            </button> */}
          </div>
          }</td>
          {columns.map((col, colIndex) => (
            (col.key!="action")?<td key={colIndex}>{row[col.key]}</td>:""
          ))}
        </tr>
        </>
      );
    });}
  };

  const renderTableHeaders = () => {

    return columns.map((col, index) => (
      <th key={index}>
        <div className='p-1'>
        {col.title}{''}
        {(col.key!="action")?
        <input
          type="text"
          className='p-2 rounded-md text-black'
          value={filterText[col.key] || ''}
          size={col.size}
          onChange={(e) => handleFilterChange(col.key, e.target.value.split(','))}
          placeholder={`Filter ${col.title}`}
        />:<></>}
        </div>
      </th>
    ));
  };



  return (
    <>
    <Navbar page={"Tyre Tracking"}/>
    <ToastContainer/>
    <div className='flex flex-row justify-between p-5'>
   
    <div>
  <button onClick={()=>{exportToExcel()}} className="hover:cursor-pointer p-2 m-2 rounded-lg bg-blue-400 border-2 border-blue-400 text-white hover:bg-white hover:text-black">Export</button>
    </div>
  
   
        <div><button onClick={()=>{setIsFilterOptionVisible(!isFilterOptionVisible)}} className="hover:cursor-pointer">{isFilterOptionVisible?<TbFilterCog size={40} color='#06d65d'/>:<TbFilterCog size={40} color='#60A5FA'/>}</button></div>
   
    </div>
    <Collapse isOpened={isFilterOptionVisible}>
        
        <div className='bg-gray-200 w-4/5 m-auto justify-center p-5 rounded-2xl'>
            <div className='flex sm:flex-row sm:flex-wrap justify-center max-sm:flex-col'>
               
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Fleet Name</label>
                <Select
  
    isMulti
    name="fleetname"
    options={fleetNameDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedFleetNameArr=[];e.map((data)=>{selectedFleetNameArr.push(data.value)});setSelectedFleetname(selectedFleetNameArr)}}
  />
                </div>
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Vehicle Reg. No.</label>
                <Select
  
    isMulti
    name="vehicleRegNo"
    options={vehicleRegNoDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedVehicleArr=[];e.map((data)=>{selectedVehicleArr.push(data.value)});setSelectedVehicleRegNo(selectedVehicleArr)}}
  />
                </div>
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Vehicle Type</label>
                <Select
  
    isMulti
    name="vehicleType"
    options={vehicleTypeDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedVehicleTypeArr=[];e.map((data)=>{selectedVehicleTypeArr.push(data.value)});setSelectedVehicleType(selectedVehicleTypeArr)}}
  />
                </div>
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Tyre Status</label>
                <Select
  
    isMulti
    name="vehicleType"
    options={tyreStatusDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedTyreStatusArr=[];e.map((data)=>{selectedTyreStatusArr.push(data.value)});setSelectedTyreStatus(selectedTyreStatusArr)}}
  />
                </div>
                <div className='sm:w-2/5 max-sm:w-full flex flex-col sm:m-1 p-3 text-center'>
                <label>Date Range</label>
                <div className='w-full'>
                <input className='m-1 p-1 rounded-sm' onChange={(e)=>{setFromDate(e.target.value.replace("-","/").replace("-","/"));console.log(fromDate)}} type={"date"}></input>
                <input className='m-1 p-1 rounded-sm' onChange={(e)=>{setToDate(e.target.value.replace("-","/").replace("-","/"));console.log(toDate)}} type={"date"}></input>
                
                </div></div>
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Tyre Size</label>
                <Select
  
    isMulti
    name="tyreSize"
    options={tyreSizeDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedTyreSizeArr=[];e.map((data)=>{selectedTyreSizeArr.push(data.value)});setSelectedTyreSize(selectedTyreSizeArr)}}
  />
                </div>
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Tyre Make & Pattern</label>
                <Select
  
    isMulti
    name="tyreMakeandPattern"
    options={tyreMakeDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedTyreMakeArr=[];e.map((data)=>{selectedTyreMakeArr.push(data.value)});setSelectedTyreMake(selectedTyreMakeArr)}}
  />
                </div>
                <div className='sm:w-2/5 max-sm:w-full sm:m-1 p-3 text-center'>
                <label>Tread Make & Pattern</label>
                <Select
  
    isMulti
    name="treadMakeandPattern"
    options={treadMakeDropdown}
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(e)=>{const selectedTreadMakeArr=[];e.map((data)=>{selectedTreadMakeArr.push(data.value)});setSelectedTyreMake(selectedTreadMakeArr)}}
  />
                </div>
            </div>
            <div className='m-auto justify-items-center w-fit justify-center'>
            <button onClick={()=>{handleSubmit()}}  className='m-auto justify-items-center bg-blue-400 text-white p-2 border-2 border-blue-400 hover:bg-slate-200 hover:text-black px-4 hover: rounded-lg'>Search</button>
            </div>
        </div>
    
        </Collapse>
        
        <div>
            {
                !tyreTrackingData.length>0? <div className='text-center mt-5'>No data to show</div>:
                <div></div>
            }
            {tyreTrackingData.length>0&&
            <>
                <div className='overflow-x-scroll m-3'>
                {!loading&&
               
                <table className="table   w-full rounded-md  bg-blue-400 text-white">
                  <thead className='sticky top-0'>
                    <tr>{renderTableHeaders()}</tr>
                  </thead>
                  <tbody  className='h-4/5 overflow-scroll'>{renderTableData()}</tbody>
                </table>
                
           }
           </div>
            
              <div className='m-5 flex justify-between max-sm:m-2'>
                <div>
                  <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage - 1)} disabled={(currentPage === 1) || loading}>
                    Previous
                  </button>
                  </div>
                  <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
                  <span className='m-2'>Page {currentPage} of {totalPages}</span>
                  <select name="pages" id="pages" onChange={(e)=>{setItemsPerPage(e.target.value)}}>
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
                  </div>
                  <div>
                  <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage + 1)} disabled={(currentPage === totalPages) || loading }>
                    Next
                  </button>
                  </div>
                </div>
                </>
            }
        </div>



    
    </>
  )
}

export default TyreTracking