import { Databases, Query }  from 'appwrite';
import React, { useMemo, useState,useEffect } from 'react'
import { useTable, useFilters, usePagination } from 'react-table';
import { ToastContainer, toast } from 'react-toastify';
import appwriteClient from '../appwrite.config';
import { CiCircleChevDown } from "react-icons/ci";
import { CiExport } from "react-icons/ci";
import {Collapse} from 'react-collapse';
import Collapsible from 'react-collapsible';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import WorkOrderUpdate from '../components/WorkOrderUpdate';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import 'react-toastify/dist/ReactToastify.css';
import WorkOrderTyreUpdate from '../components/WorkOrderTyreUpdate';
import Cookies from 'js-cookie';
import Navbar from '../components/Navbar';
import { MdOutlineDownloadDone } from "react-icons/md";



function WorkOrder() {
const [columns,setColumns]=useState([
  {
    title:"Action",
    key:"action"
  },
    {
        title:"WO No.",
        key:"workOrderNumber",
        size:"2"
    },
    {
        title:"Fleet Name",
        key:"fleetName",
        size:"20"
    },
    {
        title:"Pickup Site",
        key:"pickupSite",
        size:"10"
    },
    {
        title:"Vehicle",
        key:"vehicle",
        size:"8"
    },
    {
        title:"Driver",
        key:"driver",
        size:"8"
    },
    {
        title:"Date",
        key:"date",
        size:"7"
    },
    {
        title:"Time",
        key:"time",
        size:"3"
    },
    {
        title:"PR No.",
        key:"prno",
        size:"3"
    },
    {
      title:"Created By",
      key:"createdBy",
      size:"10"
    },
    {
      title:"Created At",
      key:"createdAt",
      size:"8"
    }
    ]);
    const [loading,setLoading]=useState(false)
    var filteredData;
const [wOData,setWOData]=useState([]);
const [tyreData,setTyreData]=useState([]);
const [lastId,setLastId]=useState("");
const [tyreDataLastId,setTyreDataLastId]=useState("")
const [itemsPerPage,setItemsPerPage]=useState(10);
const [pageNo,setPageNo]=useState(1);
const [currentPage, setCurrentPage] = useState(1);
const [updateWono,setUpdateWono]=useState();
const [updateTyreId,setUpdateTyreId]=useState();
const [totalPages,setTotalPages]=useState();
const [isUpdateFormVisible,setIsUpdateFormVisible]=useState(false);
const [isTyreDetailsUpdateFormVisible,setTyreDetailsIsUpdateFormVisible]=useState(false);
const databases = new Databases(appwriteClient);
const [presentWono,setPresentWono]=useState();
const [presentTyreId,setPresentTyreId]=useState();
const navigate = useNavigate();

  
 

 const toggleUpdateFormVisibility=(wono)=>{
    if(!isUpdateFormVisible){
     setUpdateWono(wono)
    }
   
   
    setIsUpdateFormVisible(!isUpdateFormVisible);
}

const toggleTyreDetailsUpdateFormVisibility=(tyreId)=>{

      if(!isTyreDetailsUpdateFormVisible){
        setUpdateTyreId(tyreId);
      }
      setTyreDetailsIsUpdateFormVisible(!isTyreDetailsUpdateFormVisible);
}

databases.getDocument('AppwriteCloudFEApp','SerialNumberRegister', '65eac0e7c1dff68747c9').then((response)=>{
  setPresentWono(response.serialNumber)
}).catch((error)=>{
  toast.error("An error occurred, Please try again later");
})

const deleteWorkOrder=(wono)=>{

if(presentWono){
  confirmAlert({
    title: 'Confirm to Delete ',
    message: 'Are you sure to delete this Work Order.',
    buttons: [
      {
        label: 'Yes',
        onClick:async () => {
         await databases.listDocuments('AppwriteCloudFEApp',
            'WorkOrderTyreDetails',
            [
              Query.equal("workOrderNo",wono)
            ]
          ).then((response)=>{
            response.documents.map((data)=>{
              databases.deleteDocument('AppwriteCloudFEApp','WorkOrderTyreDetails',data.$id).then((respnse)=>{

              }).catch((error)=>{
                toast.error("Unable to delete tyre data, Please try again later !!")
                console.log(error)
              })
             
            })
          }).catch((error)=>{
            toast.error("Unable to get tyre data, Please try again later !!")
            console.log(error)
          })
         await databases.deleteDocument('AppwriteCloudFEApp',
          'WorkOrder',
          wono
          ).then((respose)=>{
            
           if(wono==presentWono){
              console.log(presentWono)
              databases.updateDocument('AppwriteCloudFEApp','SerialNumberRegister', '65eac0e7c1dff68747c9',{serialNumber:presentWono-1}).then((response)=>{
                toast.success("Deleted Successfully");
              
              }).catch((error)=>{
                toast.error("An error occurred, Please try again later");
                console.log(error)
              })
            }
            toast.success("Deleted Successfully");
          }).catch((error)=>{
            toast.error("An error occurred, Please try again later");
          })
        }
      },
      {
        label: 'No',
        onClick: () => {}
      }
    ]
  });
}
}
const deleteWorkOrderTyre=(tyreId)=>{
  if(tyreId){
    confirmAlert({
      title: 'Confirm to Delete ',
      message: 'Are you sure to delete this Tyre.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            databases.deleteDocument('AppwriteCloudFEApp',
            'WorkOrderTyreDetails',
            tyreId
            ).then((respose)=>{
            
              toast.success("Deleted Successfully");
              window.location.reload();
            }).catch((error)=>{
              toast.error("An error occurred, Please try again later");
            })
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }
}

const changeStatus = async (wono,status) => {
  await databases.updateDocument(
    'AppwriteCloudFEApp',
    'WorkOrder',
    wono,{
      status:status
    }
  ).then((response)=>{
    toast.success("Work Order status changed sucessfully !!");
    window.location.reload()
  }).catch((error)=>{
    toast.error("Work Order status cann't be changed !!")
  })
}


useEffect(()=>{
  databases.listDocuments(
    'AppwriteCloudFEApp',
    'WorkOrderTyreDetails',
    [
      Query.orderDesc('workOrderNo'), 
      Query.limit(250),
    ]
  ).then((response)=>{
    console.log(response);
    setTyreDataLastId(response.documents[response.documents.length-1].$id)
    setTyreData(response.documents);
  }).catch((error)=>{
    console.log(error)
  })
  setLoading(true);
  databases.listDocuments(
     'AppwriteCloudFEApp',
     'WorkOrder',
     [
       Query.orderDesc('workOrderNumber'), 
       
       Query.limit(itemsPerPage*3),
       (currentPage>1)?Query.cursorAfter(lastId):Query.offset(0),
       //Query.offset((pageNo-1)*itemsPerPage)
 
     ]
    
 )
 .then((response)=>{
   setLastId(response.documents[response.documents.length - 1].$id);
   if(currentPage===1){setTotalPages(Math.ceil(response.total / itemsPerPage))};
    // (currentPage==1)?setWOData(response.documents):
     setWOData([...wOData,...response.documents]);
     console.log(wOData)
     console.log("First load")
   
     setLoading(false)
 }).catch((error)=>{
  toast.error("Unable to get Work orders, Please try again later !!")
     console.log(error)
     setLoading(false)
 })
},[])

useEffect(()=>{
  
if(!currentPage*itemsPerPage<wOData.length-1 ){


  setLoading(true);
  databases.listDocuments(
    'AppwriteCloudFEApp',
    'WorkOrderTyreDetails',
    [
      Query.orderDesc('workOrderNo'), 
      
      Query.limit(itemsPerPage*30),
   Query.cursorAfter(tyreDataLastId),
    ]
  ).then((response)=>{
    console.log(response);
    setTyreData([...tyreData,response.documents]);
  }).catch((error)=>{
    console.log(error)
  })
 databases.listDocuments(
    'AppwriteCloudFEApp',
    'WorkOrder',
    [
      Query.orderDesc('workOrderNumber'), 
      
      Query.limit(itemsPerPage),
   Query.cursorAfter(lastId),
      //Query.offset((pageNo-1)*itemsPerPage)

    ]
   
)
.then((response)=>{
  setLastId(response.documents[response.documents.length - 1].$id);
  if(currentPage===1){setTotalPages(Math.ceil(response.total / itemsPerPage))};
   // (currentPage==1)?setWOData(response.documents):
    setWOData([...wOData,...response.documents]);
    console.log(wOData)
    console.log("data less")
    
  
    setLoading(false)
}).catch((error)=>{
    console.log(error)
    setLoading(false)
})
}

},[currentPage,itemsPerPage,filteredData])



useEffect(()=>{

},[pageNo])

const [filterText, setFilterText] = useState({});

//Workorder preview navigation

  




  const handleFilterChange  =  (key, value) => {
 
     setFilterText(()=>{return { ...filterText, [key]: value }});
    
    
    
  };

  const applyFilters = (row) => {

    return Object.entries(filterText).every(([key, values]) => {
      
      const cellValue = row[key].toLowerCase();

      return values.some(filterValue =>{ if(filterValue){return cellValue===(filterValue.toLowerCase())}return true} );
    }
);};

   filteredData = wOData.filter(row => applyFilters(row));
  useEffect(()=>{
    if(currentPage!=1){
    setTotalPages( Math.ceil(filteredData.length / itemsPerPage))
  }
  },[totalPages,filteredData,itemsPerPage,currentPage])
  const filterTyreData = (row,wono)=>{
    
    return row.workOrderNo===wono
  }

  const handleChangePage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    
    }
  };

  const renderTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
       
    if(filteredData.length>=1){
      
    return filteredData.slice(startIndex, endIndex).map((row, index) => {

      return (
        <>
        <tr key={row.$id} className='text-center border-t-2 bg-white text-black'>
          <td>{
            <div className='flex px-3'>
            <Link to={`/workorderPreview/${row["workOrderNumber"]}`} state={{WorkOrder:row["workOrderNumber"]}} >
              <CiExport  className='mx-2 hover:cursor-pointer' color='green' size={25}/>
            </Link>
            <button onClick={()=>toggleUpdateFormVisibility(row["workOrderNumber"])}>
            <CiEdit className='mx-2 hover:cursor-pointer ' color='blue' size={25}/>
            </button>
            <button onClick={()=>deleteWorkOrder(row["workOrderNumber"])}>
            <MdDeleteOutline className='mx-2 hover:cursor-pointer' color='orange' size={25} />
            </button>
            <button onClick={()=>{changeStatus(row["workOrderNumber"],row["status"]==="Initiated"?"Generated":"Initiated");row["status"]==="Initiated"?row["status"]="Generated":row["status"]="Initiated"}}>
              {
              <MdOutlineDownloadDone className='mx-2 hover:cursor-pointer' color={`${row["status"]==="Initiated"?"brown":"#50C878"}`} size={25}/>
            }
                       
            </button>
          </div>
          }</td>
          {columns.map((col, colIndex) => (
            (col.key!="action")?<td key={colIndex}>{row[col.key]}</td>:""
          ))}
        </tr>
        <tr className='bg-white   text-black border-b-2'>
                <td colSpan={11} className='px-16 py-2'>
                    <Collapsible className="bg-white border-0 text-black" trigger={<CiCircleChevDown color='black' size={25}/>}>
                      <div className='bg-white text-black border-0  justify-center'>
                        <table style={{backgroundColor:"white" }} className="mx-60 max-sm:mx-0 rounded-md">
                        <thead>
                                <tr className='bg-blue-400 text-white'>
                                <th className='text-center px-4 border-x-2 border-y-2'>Tyre ID No.</th>
                                <th className='text-center px-4 border-x-2 border-y-2'>Tyre Make </th>
                                <th className='text-center px-4 border-x-2 border-y-2'>Tyre Size</th>
                                <th className='text-center px-4 border-x-2 border-y-2'>DOT</th>
                                <th className='text-center px-4 border-x-2 border-y-2'>Tyre Serial No.</th>
                                <th className='text-center px-4 border-x-2 border-y-2'>Remarks</th>
                                <th className='text-center px-4 border-x-2 border-y-2'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                              {
                                //console.log(row["workOrderNumber"])
                               tyreData.filter((rowData)=>filterTyreData(rowData,row["workOrderNumber"])).map((data)=>{
                                    return(
                                      <tr className='border-y-2 border-x-2'>
                                        <td className='text-center border-x-2'>{data.tyreId}</td>
                                        <td className='text-center border-x-2'>{data.tyreMake}</td>
                                        <td className='text-center border-x-2'>{data.tyreSize}</td>
                                        <td className='text-center border-x-2'>{data.dot}</td>
                                        <td className='text-center border-x-2'>{data.tyreSerialNo}</td>
                                        <td className='text-center border-x-2'>{data.remarks}</td>
                                        <td> <button onClick={()=>toggleTyreDetailsUpdateFormVisibility(data.tyreId)}>
            <CiEdit className='mx-2 hover:cursor-pointer ' color='blue' size={25}/>
            </button>
            <button onClick={()=>deleteWorkOrderTyre(data.$id)}>
            <MdDeleteOutline className='mx-2 hover:cursor-pointer' color='orange' size={25} />
            </button></td>
                                      </tr>
                                    )
                               })
                              }
                            </tbody>
                          </table>
                          </div>
                          </Collapsible>
                          </td>
                          </tr>
        </>
      );
    });}
  };

  const renderTableHeaders = () => {

    return columns.map((col, index) => (
      <th key={index}>
        <div className='p-1'>
        {col.title}{''}
        {(col.key!="action")?
        <input
          type="text"
          className='p-2 rounded-md text-black'
          value={filterText[col.key] || ''}
          size={col.size}
          onChange={(e) => handleFilterChange(col.key, e.target.value.split(','))}
          placeholder={`Filter ${col.title}`}
        />:<></>}
        </div>
      </th>
    ));
  };

  return (
    <>
    <Navbar page={"Work Order"}/>
    <div className=' m-6 rounded-sm-1/3'>
      <ToastContainer/>
    {/* <Collapse isOpened={isUpdateFormVisible}> */}
      {isUpdateFormVisible&&
      <div className='m-auto justify-center max-sm:m-3 bg-slate-200 rounded-lg text-center p-2 md:ml-56 md:mr-56  sm:absolute sm:ml-60 sm:mt-20'>
        <div className='flex-row flex justify-center m-auto text-center'>
         <h1 className='text-xl '>Work Order Update</h1>
         <button className='float-right m-1.5' onClick={toggleUpdateFormVisibility}>
         <IoMdClose className='float-right ' size={20} />
         </button>
         </div>
<WorkOrderUpdate wono={updateWono} toggleUpdateFormVisibility={toggleUpdateFormVisibility}/>
</div>}
{isTyreDetailsUpdateFormVisible&&
<div className='m-auto justify-center max-sm:m-3 bg-slate-200 rounded-lg text-center p-2 md:ml-56 md:mr-56  sm:absolute sm:ml-60 sm:mt-20'>
    <div className='flex-row flex justify-center m-auto text-center'>
         <h1 className='text-xl '>Tyre Details Update</h1>
         <button className='float-right m-1.5' onClick={toggleTyreDetailsUpdateFormVisibility}>
         <IoMdClose className='float-right ' size={20} />
         </button>
         </div>
<WorkOrderTyreUpdate tyreId={updateTyreId} toggleUpdateFormVisibility={toggleUpdateFormVisibility}/>
</div>
}
{/* </Collapse> */}
<div className='overflow-x-scroll '>
      {!loading&&
     
      <table className="table   w-full rounded-md  bg-blue-400 text-white">
        <thead className='sticky top-0'>
          <tr>{renderTableHeaders()}</tr>
        </thead>
        <tbody  className='h-4/5 overflow-scroll'>{renderTableData()}</tbody>
      </table>
      
 }
 </div>
    </div>
    <div className='m-5 flex justify-between max-sm:m-2'>
      <div>
        <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage - 1)} disabled={(currentPage === 1) || loading}>
          Previous
        </button>
        </div>
        <div className='m-2 mt-4 max-sm:m-1 max-sm:mt-2'>
        <span className='m-2'>Page {currentPage} of {totalPages}</span>
        <select name="pages" id="pages" onChange={(e)=>{setItemsPerPage(e.target.value)}}>
  <option value="10">10</option>
  <option value="30">30</option>
  <option value="50">50</option>
  <option value="100">100</option>
</select>
        </div>
        <div>
        <button className='bg-blue-400 text-white p-2 rounded-md w-24 m-2 max-sm:m-1' onClick={() => handleChangePage(currentPage + 1)} disabled={(currentPage === totalPages) || loading }>
          Next
        </button>
        </div>
      </div>
    </>
  );
}

export default WorkOrder